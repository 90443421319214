import React, { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MUITextField from "../../Common/MUITextField";
import { useState } from "react";
import MUIPasswordField from "../../Common/MUIPasswordField";
import {
  createBoard,
  deleteValidationErrors,
  validateInputFields,
} from "./BoardsFunctions";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import SaveButton from "../../Common/SaveButton";
import UserSelectMulti from "../Users/UserSelectMulti";
import { UserContext } from "../Users/UserContext";
import * as Icons from "react-icons/fc";
import { updateState } from "../Surveys/surveysFunctions";
import IconModal from "../../Common/IconModal";
import PrivacyOptions from "./PrivacyOptions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { generateEncryptionKey, generateRoomId } from "./boardFunctions.ts";

export default function CreateBoard() {
  const [icon, setIcon] = React.useState("FcAddDatabase");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const IconComponent = Icons[icon];
  const { currentUserData } = useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [boardnameErrorText, setBoardnameErrorText] = useState(
    "Pflichtfeld bitte befüllen"
  );
  const [newBoardData, setNewBoardData] = useState({
    id: 0,
    boardname: "",
    owner: currentUserData.id,
    isPasswordProtected: 0,
    password: "",
    description: "",
    boardicon: "",
    privacy: "privat",
    roomId:"",
    usersSelected: [],
  });

  const [errorNewBoardData, setErrorNewBoardData] = useState({
    errorBoardname: false,
    errorPassword: false,
  });

  const updateErrorState = (variableName, value) => {
    setErrorNewBoardData((prevState) => ({
      ...prevState,
      [variableName]: value,
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    generateRoomId().then((roomId) => {
      generateEncryptionKey().then((key) => {
      updateState("roomId", roomId+","+key, setNewBoardData)})
    })
  }, []);

  const handleChange = (variable, variableError, event) => {
    setSuccess(false);
    if (event && event.target) {
      updateState(variable, event.target.value, setNewBoardData);
      updateErrorState(variableError, false);
    }
  };

  useEffect(() => {
    setNewBoardData(newBoardData);
  }, [newBoardData]);

  useEffect(() => {
    updateState("boardicon", icon, setNewBoardData);
  }, [icon]);

  const handleSubmit = () => {
    validateInputFields(newBoardData, setErrorNewBoardData).then((response) => {
      var allChanged = response;
      if (allChanged) {
        deleteValidationErrors(newBoardData);
        createBoard(newBoardData, navigate);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <h5 className="header">Board hinzufügen</h5>
      <Stack
        width="100%"
        style={{ flexWrap: "wrap", gap: "10px" }}
        direction={{ sm: "column", md: "row" }}
        spacing={0}
      >
        <Fade in={true}>
          <Paper
            className="paper"
            sx={{
              display: "flex",
              width: "25%",
              minWidth: "300px",
              alignItems: "center",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <IconComponent style={{ width: "150px", height: "150px" }} />
            <Button variant="outlined" onClick={() => setOpen(true)}>
              Icon wählen
            </Button>
            <IconModal
              open={open}
              handleClose={handleClose}
              icon={icon}
              setIcon={setIcon}
            />
          </Paper>
        </Fade>
        <Fade in={true}>
          <Paper
            className="paper"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              minWidth: "400px",
              gap: "10px",
            }}
          >
            <MUITextField
              handleChange={(event) =>
                handleChange("boardname", "errorBoardname", event)
              }
              value={newBoardData.boardname}
              id="Boardname"
              label="Boardname"
              variant="standard"
              error={errorNewBoardData.errorBoardname}
              helperText={boardnameErrorText}
            />
            <MUITextField
              handleChange={(event) =>
                handleChange("description", "errorEmail", event)
              }
              value={newBoardData.description}
              id="Beschreibung"
              label="Beschreibung"
              variant="standard"
            />
            <PrivacyOptions setNewBoardData={setNewBoardData} />
            {newBoardData.privacy === "intern" && (
              <UserSelectMulti data={newBoardData} setData={setNewBoardData} />
            )}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Passwort geschützt"
                onChange={() =>
                  updateState(
                    "isPasswordProtected",
                    !newBoardData.isPasswordProtected,
                    setNewBoardData
                  )
                }
              />
            </FormGroup>
            {newBoardData.isPasswordProtected ? (
              <MUIPasswordField
                inputPropsStyle={{ width: "100%" }}
                type="password"
                id="Password"
                label="Password"
                variant="standard"
                handleChange={(event) =>
                  handleChange("password", "errorPassword", event)
                }
                value={newBoardData.password}
                error={errorNewBoardData.errorPassword}
                helperText={
                  "Passwort mit mindestens 6 Zeichen und einem Sonderzeichen eingeben"
                }
              />
            ):""}
            <p />
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => navigate("/boards")}>
                Zurück
              </Button>
              <SaveButton
                handleSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
                success={success}
              />
            </Stack>
          </Paper>
        </Fade>
        <br />
      </Stack>
    </>
  );
}
