import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const MUITable = (props) => {
  return (
    <DataGrid
      onRowClick={props.onRowClick}
      rows={props.rows}
      columns={props.columns}
      slots={{
        toolbar: CustomToolbar,
      }}
      rowHeight={60}
      autoHeight
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[10, 25, 50]}
      sx={{ border: 0 }}
    />
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer >
        <GridToolbarQuickFilter placeholder='Suche' style={{width:"60%", minWidth:"200px", paddingBottom:"10px", paddingTop:"15px", paddingLeft:"5px"}} />
        {props.isExportable !== false &&<GridToolbarExport style={{position:"absolute", right:"10px"}}/>}
      </GridToolbarContainer>
    );
  }
  
};

export default MUITable;
