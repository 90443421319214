import { sendGetRequest } from "../../Common/sendGetRequest";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { setCookie } from "../../Common/setCookie";
import { showToaster } from "../../Common/showToaster";

export function getUsers(setRows, setDataFetched) {
  sendGetRequest("getUsers.php").then((response) => {
    setRows(response);
    setDataFetched(true);
  });
}

export const containsSpecialCharacters = (input) => {
  const specialCharactersRegex = /[!@#$%^&*(),.?"':{}|<>]/;
  return specialCharactersRegex.test(input);
};

export function createUser(
  newUserData,
  updateState,
  updateErrorState,
  setEmailErrorText,
  setSuccess,
  avatarChanged,
  setLoading,
  setUploadTrigger,
  uploadTrigger,
  setAvatarChanged,
  navigate
) {
  sendPostRequest("createUser.php", newUserData).then((response) => {
    let responseObject = JSON.parse(response);
    
    if (
      responseObject.message.includes("Benutzer wurde erfolgreich angelegt")
    ) {
      if (!avatarChanged) {
        localStorage.setItem(
          "toastMessage",
          "Benutzer wurde erfolgreich angelegt"
        );
        navigate("/users");
      } else {
        localStorage.setItem(
          "toastMessage",
          "Benutzer wurde erfolgreich angelegt"
        );
        updateState("id", responseObject.id);
        setUploadTrigger(uploadTrigger + 1);
        setAvatarChanged(false);
      }
    }

    if (responseObject.emailerror.includes("email")) {
      setEmailErrorText("Email bereits vergeben");
      updateErrorState("errorEmail", true);
      setLoading(false);
    }
  });
}

export function editUser(
  newUserData,
  updateErrorState,
  setEmailErrorText,
  setSuccess,
  avatarChanged,
  setLoading,
  setUploadTrigger,
  uploadTrigger,
  setAvatarChanged
) {
  sendPostRequest("editUser.php", newUserData).then((response) => {
    if (response.includes("Änderungen wurden erfolgreich gespeichert")) {
      if (!avatarChanged) {
        showToaster(
          response,
          "Ein unerwarteter Fehler ist aufgetreten",
          "Änderungen wurden erfolgreich gespeichert"
        );
        setSuccess(true);
        setLoading(false);
      } else {
        setUploadTrigger(uploadTrigger + 1);
        setAvatarChanged(false);
      }
    }

    if (response.includes("email")) {
      
      setEmailErrorText("Email bereits vergeben");
      updateErrorState("errorEmail", true);
      setLoading(false);
    }
  });
}

export function editCurrentUser(
  newUserData,
  updateErrorState,
  setEmailErrorText,
  setSuccess,
  avatarChanged,
  setLoading,
  setUploadTrigger,
  uploadTrigger,
  setAvatarChanged
) {
  sendPostRequest("editCurrentUser.php", newUserData).then((response) => {
    if (response.includes("Änderungen wurden erfolgreich gespeichert")) {
      if (!avatarChanged) {
        showToaster(
          response,
          "Ein unerwarteter Fehler ist aufgetreten",
          "Änderungen wurden erfolgreich gespeichert"
        );
        setSuccess(true);
        setLoading(false);
      } else {
        setUploadTrigger(uploadTrigger + 1);
        setAvatarChanged(false);
      }
    }

    if (response.includes("email")) {
      
      setEmailErrorText("Email bereits vergeben");
      updateErrorState("errorEmail", true);
      setLoading(false);
    }
  });
}

export function getTrainerDetails(setTrainerName, trainerId, setDataFetched) {
  sendGetRequest("getUserDetails.php?id=" + trainerId).then(
    (response) =>
      setTrainerName(response.Vorname + " " + response.Nachname) +
      setDataFetched(true)
  );
}

export function getUserDetails(updateState, userId) {
  sendGetRequest("getUserDetails.php?id=" + userId).then(
    (response) =>
      updateState("name", response.Vorname + " " + response.Nachname) +
      updateState("vorname", response.Vorname) +
      updateState("nachname", response.Nachname) +
      updateState("email", response.Email) +
      updateState("role", response.Role) +
      updateState("description", response.Description) +
      updateState("avatarfile", response.AvatarFile)
  );
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  if (password.length < 6) {
    return false;
  }
  const passwordRegex = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
  return passwordRegex.test(password);
}

export async function validateInputFields(newUserData, setErrorNewUserData) {
  const errors = {};
  var allChanged = true;

  // Check if each variable has changed from initial state
  const promises = Object.entries(newUserData).map(([key, value]) => {
    if (key === "email" && !isValidEmail(value)) {
      allChanged = false;
      errors[`errorEmail`] = true;
    }
    if (key === "password" && !isValidPassword(value)) {
      allChanged = false;
      errors[`errorPassword`] = true;
    }
    if (key === "role" && value === "") {
      allChanged = false;
      errors[`errorRole`] = true;
    }

    if (
      key === "vorname" &&
      (value.length === 0 || containsSpecialCharacters(value))
    ) {
      allChanged = false;
      errors[`errorVorname`] = true;
    }
    if (
      key === "nachname" &&
      (value.length === 0 || containsSpecialCharacters(value))
    ) {
      allChanged = false;
      errors[`errorNachname`] = true;
    }
    return Promise.resolve();
  });

  await Promise.all(promises);
  if (!allChanged) {
    setErrorNewUserData((prevState) => ({
      ...prevState,
      ...errors,
    }));
  }
  return allChanged;
}

export function deleteValidationErrors(newUserData) {
  const errors = {};

  Object.entries(newUserData).forEach(([key, value]) => {
    errors[`error${key.charAt(0).toUpperCase()}${key.slice(1)}`] = false;
  });
}
