import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { showToaster } from "../../Common/showToaster";
import ConfirmModal from "../../Common/ConfirmModal";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteOption = (props) => {
  //for Modal
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const modalText = "Möchten sie das Board <b>"+props.cellValues.row.Title+"</b> unwiderruflich löschen?"
  const modalHeader = "Board löschen"

  const deleteBoard = () => {
    sendPostRequest(`deleteBoard.php?id=${props.cellValues.row.id}`).then((response) =>
      showToaster(
        response,
        "Ein unerwarteter Fehler ist aufgetreten",
        "Board wurde erfolgreich gelöscht"
      )
    );
    handleClose();
    let reducedRows = props.rows.filter(item => item.id !== props.cellValues.row.id);
    props.setRows(reducedRows)
  };

  return (
    <>
          <MenuItem onClick={handleClickOpen} disableRipple>
      <DeleteIcon/> Board löschen
      </MenuItem>
      <ConfirmModal open={open} handleClose={handleClose} action={deleteBoard} buttonText="Löschen" modalText={modalText} modalHeader={modalHeader} />
    </>
  );
};

export default DeleteOption;
