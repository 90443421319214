import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { showToaster } from "./showToaster";

export default function SaveButton(props) {
  const timer = React.useRef();

  const buttonSx = {
    ...(props.success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  React.useEffect(() => {
  if (props.loading){
  showToaster(
    "Änderungen wurden erfolgreich gespeichert",
    "Fehler - Änderungen wurden nicht übernommen",
    "Änderungen wurden erfolgreich gespeichert"
  );
  }}, [props.success]);


  const handleButtonClick = () => {
    props.setLoading(true);
    props.handleSubmit();
  };

  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Button
      id="SaveButton"
        variant="contained"
        sx={buttonSx}
        disabled={props.disabled?props.disabled:props.loading}
        onClick={handleButtonClick}
        type="submit"
      >
        Speichern
      </Button>
      {props.loading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
}
