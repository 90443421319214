import React, { useEffect } from "react";
import { getUserRoleFromJWT } from "./getUserRoleFromJWT";
import { useNavigate } from "react-router-dom";
import msalInstance from "./authConfig";
import { validateCredentials } from "./validateCredentials";
import { getCookie } from "../Common/getCookie";

export function useSSOLogin() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const jwtTokenCookie = getCookie("jwt");
    if (!jwtTokenCookie || jwtTokenCookie === "null") {
      const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      if (isLoggedIn) {
        msalInstance.initialize().then(() => {
          msalInstance
            .ssoSilent({
              scopes: ["User.Read"],
              domainHint: "cads.at",
            })
            .then((response) => {
              console.log("HandleSSO:", response);
              HandleSSOSubmit("kochdominic@hotmail.com", "123456!");
            })
            .catch((error) => {
              console.info("SSO Silent fehlgeschlagen:", error);
              navigate("./login");
            });
        });
      } else {
        navigate("./login");
      }
    }
  }, [navigate]);

  const HandleSSOSubmit = (email, password) => {
    validateCredentials({ email, password }).then(function (isValid) {
      if (isValid) {
        navigate("./dashboard");
      }
    });
  };

  return "";
}
