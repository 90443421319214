import React, { useEffect } from "react";
import { updateState } from "./surveysFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { translateText } from "../../Common/translateText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import MUITextField from "../../Common/MUITextField";
import { sendGetRequest } from "../../Common/sendGetRequest";

export function WelcomeAndThanksText({ texts, setTexts, surveyId, target }) {
  useEffect(() => {
    if (surveyId === 0) {
      if(target === "Kursteilnehmer")
      {
        sendGetRequest("getStandardTexts.php").then((response) => {
          updateState("welcome", response.SurveyWelcome, setTexts);
          updateState("welcomeEnglish", response.SurveyWelcomeEnglish, setTexts);
          updateState("thanks", response.SurveyThanks, setTexts);
          updateState("thanksEnglish", response.SurveyThanksEnglish, setTexts);
        });
      }
      else{
        sendGetRequest("getStandardTexts.php").then((response) => {
          updateState("welcome", response.InternalWelcome, setTexts);
          updateState("welcomeEnglish", response.InternalWelcomeEnglish, setTexts);
          updateState("thanks", response.InternalThanks, setTexts);
          updateState("thanksEnglish", response.InternalThanksEnglish, setTexts);
        });
      }
    }
  }, [target]);
  const handleWelcome = (event) => {
    if (event && event.target) {
      updateState("welcome", event.target.value, setTexts);
    }
  };

  const handleWelcomeEnglish = (event) => {
    if (event && event.target) {
      updateState("welcomeEnglish", event.target.value, setTexts);
    }
  };

  const handleThanks = (event) => {
    if (event && event.target) {
      updateState("thanks", event.target.value, setTexts);
    }
  };

  const handleThanksEnglish = (event) => {
    if (event && event.target) {
      updateState("thanksEnglish", event.target.value, setTexts);
    }
  };
  return (
    <>
      <Accordion disabled={target === ""}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Willkomensseite (erste Seite vor Feedback Abgabe)
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <MUITextField
              style={{
                width: "100%",
              }}
              handleChange={(event) => handleWelcome(event)}
              value={texts.welcome}
              label="Text Willkommensseite"
              variant="outlined"
              isMultilineText={true}
              rows={3}
            />
            <MUITextField
              style={{
                width: "100%",
              }}
              handleChange={(event) => handleWelcomeEnglish(event)}
              value={texts.welcomeEnglish}
              label="Englische Übersetzung"
              variant="outlined"
              isMultilineText={true}
              rows={3}
              InputProps={{
                endAdornment: (
                  <Tooltip title="KI-Übersetzung">
                    <InputAdornment
                      style={{
                        cursor: "pointer",
                      }}
                      position="end"
                    >
                      <AutoAwesomeIcon
                        onClick={() =>
                          translateText(
                            texts.welcome,
                            setTexts,
                            "welcomeEnglish"
                          )
                        }
                      ></AutoAwesomeIcon>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled={target === ""}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Dankesseite (letzte Seite nach Feedbackabgabe)
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <MUITextField
              style={{
                width: "100%",
              }}
              handleChange={(event) => handleThanks(event)}
              value={texts.thanks}
              label="Text Dankesseite"
              variant="outlined"
              isMultilineText={true}
              rows={3}
            />
            <MUITextField
              style={{
                width: "100%",
              }}
              handleChange={(event) => handleThanksEnglish(event)}
              value={texts.thanksEnglish}
              label="Englische Übersetzung"
              variant="outlined"
              isMultilineText={true}
              rows={3}
              InputProps={{
                endAdornment: (
                  <Tooltip title="KI-Übersetzung">
                    <InputAdornment
                      style={{
                        cursor: "pointer",
                      }}
                      position="end"
                    >
                      <AutoAwesomeIcon
                        onClick={() =>
                          translateText(texts.thanks, setTexts, "thanksEnglish")
                        }
                      ></AutoAwesomeIcon>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
