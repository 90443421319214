import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Icons from "react-icons/fc";  // This imports all Fc icons
import { flatColorIcons } from './ColorIcons';  // Assuming this array is correct

export default function IconModal(props) {
  let open = props.open;

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: "300px", textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title" style={{textAlign:"right", padding:"5px"}}>
        <Button variant="standard" onClick={handleClose}>X</Button>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {flatColorIcons.map((fcicon, index) => {
              const IconComponenttemp = Icons[fcicon];
              if (!IconComponenttemp) {
                console.warn(`Icon ${fcicon} not found in react-icons/fc`);
                return null; // Skip rendering if the icon is not found
              }
              return (
                <div onClick={()=> props.setIcon(fcicon) + props.handleClose()} key={index} style={{ margin: '10px', cursor:"pointer" }}>
                  <IconComponenttemp style={{ width: "50px", height: "50px" }} />
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
