import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import "./components/Portal/Layout/layout.css";
import "bootstrap/dist/css/bootstrap.css";
import { getCookie } from "./components/Common/getCookie";
import { setCookie } from "./components/Common/setCookie";
import PrivateRoutes from "./components/Common/PrivateRoutes";
import LoginFallback from "./components/Portal/Login/LoginFallback";
import Texts from "./components/Portal/Settings/Texts";
import Boards from "./components/Portal/Boards/Boards";
import CreateBoard from "./components/Portal/Boards/CreateBoard";

const NoPermission = lazy(() =>
  import("./components/Portal/NoPermission/NoPermission")
);
const Launch = lazy(() => import("./components/Portal/Login/Launch"));
const Login = lazy(() => import("./components/Portal/Login/Login"));
const PasswordReset = lazy(() =>
  import("./components/Portal/Login/PasswordReset")
);
const PasswordResetConfirm = lazy(() =>
  import("./components/Portal/Login/PasswordResetConfirm")
);
const CreateNewUser = lazy(() =>
  import("./components/Portal/Login/CreateNewUser")
);
const Pending = lazy(() => import("./components/Portal/Login/Pending"));
const AdminRoutes = lazy(() => import("./components/Common/AdminRoutes"));
const Categories = lazy(() =>
  import("./components/Portal/Settings/Categories")
);
const Automation = lazy(() =>
  import("./components/Portal/Automation/Automation")
);
const CreateAutomation = lazy(() =>
  import("./components/Portal/Automation/CreateAutomation")
);
const EditAutomation = lazy(() =>
  import("./components/Portal/Automation/EditAutomation")
);
const Activities = lazy(() =>
  import("./components/Portal/Settings/Activities")
);

const Dashboard = lazy(() =>
  import("./components/Portal/Feedback/Dashboard/Dashboard")
);

const Evaluation = lazy(() =>
  import("./components/Portal/Feedback/Evaluation/Evaluation")
);

const FeedbackLink = lazy(() =>
  import("./components/Portal/Link/FeedbackLink")
);
const EditUser = lazy(() => import("./components/Portal/Users/EditUser"));
const EditBoard = lazy(() => import("./components/Portal/Boards/EditBoards"));
const LookAndFeel = lazy(() =>
  import("./components/Portal/Settings/LookAndFeel")
);
const EvaluationOptions = lazy(() =>
  import("./components/Portal/Settings/EvaluationOptions")
);
const Survey = lazy(() => import("./components/Survey/Survey"));
const EditSurveys = lazy(() =>
  import("./components/Portal/Surveys/EditSurveys")
);
const CreateSurvey = lazy(() =>
  import("./components/Portal/Surveys/CreateSurvey")
);
const CreateQuestions = lazy(() =>
  import("./components/Portal/Questions/CreateQuestions")
);
const EditQuestions = lazy(() =>
  import("./components/Portal/Questions/EditQuestions")
);
const Surveys = lazy(() => import("./components/Portal/Surveys/Surveys"));
const Questions = lazy(() => import("./components/Portal/Questions/Questions"));
const Profile = lazy(() => import("./components/Portal/Profile/Profile"));
const Users = lazy(() => import("./components/Portal/Users/Users"));
const CreateUser = lazy(() => import("./components/Portal/Users/CreateUser"));
const EditCourses = lazy(() =>
  import("./components/Portal/Courses/EditCourses")
);

const CreateCourse = lazy(() =>
  import("./components/Portal/Courses/CreateCourse")
);
const Courses = lazy(() => import("./components/Portal/Courses/Courses"));

const App = () => {
  document.addEventListener("click", function (event) {
    const expirationDate = new Date(Date.now() + 86400 * 1000); // Set the expiration date to 1 day from the current time
    const jwt = getCookie("jwt");
    setCookie("jwt", jwt, expirationDate);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Launch />} />
        <Route path="/" element={<Launch />} />
        <Route path="/login" element={ <Suspense fallback={<LoginFallback/>}><Login /></Suspense>} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/newuser" element={ <Suspense fallback={<LoginFallback/>}><CreateNewUser /></Suspense>} />
        <Route path="/passwordreset" element={ <Suspense fallback={<LoginFallback/>}><PasswordReset /></Suspense>} />
        <Route path="/passwordresetconfirm" element={<PasswordResetConfirm />} />
        <Route path="/survey" element={<Survey />} />
        <Route element={<PrivateRoutes />}>
        <Route path="/nopermission" element={<NoPermission />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/boards" element={<Boards />} />
          <Route path="boards/create" element={<CreateBoard />} />
          <Route path="boards/edit/:boardId" element={<EditBoard />} />
          <Route path="/evaluation/:target/:view" element={<Evaluation />} />
          <Route path="evaluation/:target/:view/:Id" element={<Evaluation />} />
          <Route path="/profile" element={<Profile />} />
          <Route element={<AdminRoutes />}>
            <Route path="/link" element={<FeedbackLink />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="courses/edit/:courseId" element={<EditCourses />} />
            <Route path="courses/create" element={<CreateCourse />} />
            <Route path="/users" element={<Users />} />
            <Route path="users/create" element={<CreateUser />} />
            <Route path="users/edit/:userId" element={<EditUser />} />
            <Route path="lookandfeel" element={<LookAndFeel />} />
            <Route path="evaluationoptions" element={<EvaluationOptions />} />
            <Route path="automation" element={<Automation />} />
            <Route path="automation/create" element={<CreateAutomation />} />
            <Route path="automation/edit/:automationId" element={<EditAutomation />} />
            <Route path="categories" element={<Categories />} />
            <Route path="texts" element={<Texts />} />
            <Route path="questions" element={<Questions />} />
            <Route path="questions/edit/:questionId" element={<EditQuestions />} />
            <Route path="questions/create" element={<CreateQuestions />} />
            <Route path="/surveys" element={<Surveys />} />
            <Route path="surveys/edit/:surveyId" element={<EditSurveys />} />
            <Route path="surveys/create" element={<CreateSurvey />} />
            <Route path="/activitiesxy" element={<Activities />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;

