import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { updateState } from '../Surveys/surveysFunctions';

export default function PrivacyOptions({privacy, setNewBoardData}) {

  console.log(privacy)
  const[radioValue, setRadioValue] = React.useState(privacy)
  function handleChange(event){
    setRadioValue(event.target.value)
    updateState("privacy", event.target.value, setNewBoardData)
  }
  return (
    <FormControl style={{marginTop:"10px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Sichtbarkeit</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={radioValue}
      >
        <FormControlLabel onChange={handleChange} value="privat" control={<Radio />} label="Privat" />
        <FormControlLabel onChange={handleChange} value="intern" control={<Radio />} label="Intern" />
        <FormControlLabel onChange={handleChange} value="public" control={<Radio />} label="Öffentlich" />
      </RadioGroup>
    </FormControl>
  );
}