export const generateRoomId = async () => {
    const buffer = new Uint8Array(10);
    window.crypto.getRandomValues(buffer);
    return bytesToHexString(buffer);
  };

  export const bytesToHexString = (bytes: Uint8Array) => {
    return Array.from(bytes)
      .map((byte) => `0${byte.toString(16)}`.slice(-2))
      .join("");
  };

  export const generateEncryptionKey = async <
  T extends "string" | "cryptoKey" = "string",
>(
  returnAs?: T,
): Promise<T extends "cryptoKey" ? CryptoKey : string> => {
  const key = await window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 128,
    },
    true, // extractable
    ["encrypt", "decrypt"],
  );
  return (
    returnAs === "cryptoKey"
      ? key
      : (await window.crypto.subtle.exportKey("jwk", key)).k
  ) as T extends "cryptoKey" ? CryptoKey : string;
};