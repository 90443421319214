import {deleteCookie} from '../Common/deleteCookie';

export function logout() {
    localStorage.setItem("isLoggedIn", false);
    deleteCookie('jwt');
    window.location.href = '/'
  }




