import React, { useEffect } from "react";
import MUITable from "../../Common/MUITable";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import { showCreatedToaster } from "../../Common/showToaster";
import Options from "./Options";
import Stack from "@mui/material/Stack";
import whiteboardImage from "../../Assets/whiteboard.png";
import Spinner from "../../Common/Spinner";
import { useActiveNavigation } from "../Sidebar/useActiveNavigation";
import { sendGetRequest } from "../../Common/sendGetRequest";
import * as Icons from "react-icons/fc";
import ListItemText from "@mui/material/ListItemText";
import { baseURL } from "../../Common/baseURL";

export default function Boards() {
  useActiveNavigation({ activePage: "boards", activeSubPage: "", activeModule: "Coworking" });
  const [rows, setRows] = React.useState([]);
  const [datafetched, setDataFetched] = React.useState(false);
  const navigate = useNavigate();

  function getAllBoards(setRows, setDataFetched) {
    sendGetRequest("getAllBoards.php").then(
      (response) => setRows(response) + setDataFetched(true)
    );
  }

  useEffect(() => {
    showCreatedToaster();
    getAllBoards(setRows, setDataFetched);
  }, []);

  const columns = [
    {
      field: "Icon",
      renderCell: (cellValues) => {
        const IconComponent = Icons[cellValues.row.Icon];
        return <IconComponent style={{ width: "40px", height: "40px" }} />;
      },
      headerName: "",
      flex: 1,
      minWidth: 40,
    },
    {
      headerName: "Board",
      renderCell: (cellValues) => {
        return (
          <ListItemText
            primary={cellValues.row.Title}
            secondary={cellValues.row.Description}
          />
        );
      },
      flex: 4,
      minWidth: 300,
    },
    { field: "Ersteller", headerName: "Ersteller", flex: 4, minWidth: 300 },
    {
      field: "IsCollaborating",
      headerName: "Sichtbarkeit",
      flex: 3,
      minWidth: 300,
    },
    {
      field: "Aktion",
      renderCell: (cellValues) => {
        return (
          <>
            <Options
              cellValues={cellValues}
              rows={rows}
              setRows={setRows}
              setDataFetched={setDataFetched}
            />
          </>
        );
      },
      flex: 1,
      minWidth: 80,
    },
  ];

  // Handle row click and navigate to the desired page
  const handleRowClick = (params) => {
    let subdomain = baseURL.replace("/api/", "/collaboration#room="+params.row.RoomId);
    window.location.href = subdomain;
  };

  return (
    <>
      <ToastContainer />
      <Stack
        className="header"
        direction="row"
        spacing={{ xs: 2, sm: 1, md: 2 }}
      >
        <img className="pageIcon" src={whiteboardImage} alt="Usergroup" />
        <p>
          <h5>Boards</h5>
          <p className="siteInfo">
            Füge neue Zeichenboards hinzu und bearbeite bestehende Boards
          </p>
        </p>
      </Stack>
      {!datafetched ? (
        <Spinner />
      ) : (
        <Fade in={true}>
          <Paper className="paper">
            <Button
              variant="contained"
              style={{ marginBottom: "10px" }}
              onClick={() => navigate("./create")}
            >
              Board hinzufügen
            </Button>
            <MUITable
              rows={rows}
              columns={columns}
              onRowClick={handleRowClick} // Add this prop to handle row click
            />
          </Paper>
        </Fade>
      )}
    </>
  );
}
