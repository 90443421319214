export const flatColorIcons = [
    "FcAcceptDatabase",
    "FcAddDatabase",
    "FcAdvance",
    "FcAdvertising",
    "FcAlarmClock",
    "FcAlphabeticalSortingAz",
    "FcAlphabeticalSortingZa",
    "FcAndroidOs",
    "FcAnswers",
    "FcApproval",
    "FcAreaChart",
    "FcAssistant",
    "FcAutomotive",
    "FcAutomatic",
    "FcBearish",
    "FcBinoculars",
    "FcBiohazard",
    "FcBiomass",
    "FcBiotech",
    "FcBookmark",
    "FcBriefcase",
    "FcBrokenLink",
    "FcBullish",
    "FcBullishChart",
    "FcBusinessContact",
    "FcBusinessman",
    "FcBusinesswoman",
    "FcButtingIn",
    "FcCableRelease",
    "FcCalculator",
    "FcCalendar",
    "FcCamcorder",
    "FcCamera",
    "FcCancel",
    "FcCandleSticks",
    "FcCapacitor",
    "FcCdLogo",
    "FcCellPhone",
    "FcChargeBattery",
    "FcCheckmark",
    "FcCircuit",
    "FcClearFilters",
    "FcClock",
    "FcCloseUpMode",
    "FcComboChart",
    "FcCommandLine",
    "FcComments",
    "FcCompactCamera",
    "FcConferenceCall",
    "FcContacts",
    "FcControlPanel",
    "FcCopyright",
    "FcCrystalOscillator",
    "FcCurrencyExchange",
    "FcCustomerSupport",
    "FcDataBackup",
    "FcDataConfiguration",
    "FcDataEncryption",
    "FcDataProtection",
    "FcDatabase",
    "FcDebian",
    "FcDebt",
    "FcDecision",
    "FcDeleteDatabase",
    "FcDepartment",
    "FcDeployment",
    "FcDiploma",
    "FcDisapprove",
    "FcDislike",
    "FcDisplay",
    "FcDoNotInhale",
    "FcDoNotInsert",
    "FcDonate",
    "FcDownLeft",
    "FcDownRight",
    "FcDownload",
    "FcDribbble",
    "FcElectricity",
    "FcElectroDevices",
    "FcElectronics",
    "FcEmptyBattery",
    "FcEngineering",
    "FcEnteringHeavenAlive",
    "FcExpand",
    "FcExpired",
    "FcExport",
    "FcFactory",
    "FcFaq",
    "FcFastDown",
    "FcFastForward",
    "FcFaq",
    "FcFilmReel",
    "FcFinePrint",
    "FcFlashAuto",
    "FcFlashOff",
    "FcFlashOn",
    "FcFlowChart",
    "FcFolder",
    "FcFrame",
    "FcFullBattery",
    "FcGallery",
    "FcGenealogy",
    "FcGlobe",
    "FcGoogle",
    "FcGraduationCap",
    "FcGrid",
    "FcHeadset",
    "FcHeatMap",
    "FcHighBattery",
    "FcHighPriority",
    "FcHome",
    "FcIcons8Cup",
    "FcIdea",
    "FcImport",
    "FcInTransit",
    "FcInsolvency",
    "FcInspection",
    "FcInternal",
    "FcInvite",
    "FcIpad",
    "FcIphone",
    "FcKey",
    "FcLandscape",
    "FcLeave",
    "FcLeft",
    "FcLike",
    "FcLineChart",
    "FcLock",
    "FcLowBattery",
    "FcManager",
    "FcMediumPriority",
    "FcMenu",
    "FcMindMap",
    "FcMinus",
    "FcMissedCall",
    "FcMoneyTransfer",
    "FcMultipleCameras",
    "FcMusic",
    "FcNegative",
    "FcNews",
    "FcNfcSign",
    "FcNightPortrait",
    "FcNightlandscape",
    "FcNoIdea",
    "FcNoVideo",
    "FcOk",
    "FcOldTimeCamera",
    "FcOnlineSupport",
    "FcOpenedFolder",
    "FcOrgUnit",
    "FcOrganization",
    "FcOvertime",
    "FcPackage",
    "FcPaid",
    "FcPanorama",
    "FcParallelTasks",
    "FcPhone",
    "FcPhotoReel",
    "FcPicture",
    "FcPieChart",
    "FcPlanner",
    "FcPlus",
    "FcPodiumWithAudience",
    "FcPodiumWithSpeaker",
    "FcPodiumWithoutSpeaker",
    "FcPortraitMode",
    "FcPositiveDynamic",
    "FcPrevious",
    "FcPrint",
    "FcPrivacy",
    "FcProcess",
    "FcPuzzle",
    "FcQuestions",
    "FcRadarPlot",
    "FcRating",
    "FcRatingThreeStars",
    "FcReading",
    "FcReddit",
    "FcRedo",
    "FcRefresh",
    "FcRegisteredTrademark",
    "FcRemoveImage",
    "FcRightDown",
    "FcRightUp",
    "FcRuler",
    "FcRules",
    "FcSafe",
    "FcSalesPerformance",
    "FcScatterPlot",
    "FcSearch",
    "FcSelfServiceKiosk",
    "FcSelfie",
    "FcSerialTasks",
    "FcServiceMark",
    "FcShare",
    "FcShipped",
    "FcShop",
    "FcSignature",
    "FcSimCard",
    "FcSimCardChip",
    "FcSmartphoneTablet",
    "FcSms",
    "FcSoundRecordingCopyright",
    "FcSpeaker",
    "FcSportsMode",
    "FcStackOfPhotos",
    "FcStart",
    "FcStatistics",
    "FcStereoMix",
    "FcStumbleupon",
    "FcSurvey",
    "FcSwitchCamera",
    "FcSynchronize",
    "FcTablet",
    "FcTemplate",
    "FcTimeline",
    "FcTodoList",
    "FcTrademark",
    "FcTreeStructure",
    "FcUndo",
    "FcUnlock",
    "FcUp",
    "FcUpload",
    "FcUsb",
    "FcVideoCall",
    "FcVideoFile",
    "FcVoicePresentation",
    "FcVoicemail",
    "FcViewDetails",
    "FcWebCamera",
    "FcWiFiLogo",
    "FcWikipedia",
    "FcWorkflow",
    "FcXls",
    "FcZoomIn",
    "FcZoomOut",
  ];
  