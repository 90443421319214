import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { updateState } from "../Surveys/surveysFunctions";
import { getUsers } from "./UsersFunctions";
import UserAvatar from "./UserAvatar";
import Tooltip from "@mui/material/Tooltip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UserSelectMulti({ data, setData, style }) {
  const [users, setUsers] = React.useState([]);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [visibility, setVisibility] = React.useState(false);

  const isOptionSelected = (option) => {
    const selectedTitles = data.usersSelected.map((user) => user.id);
    return selectedTitles.includes(option.id);
  };

  function removeMultipleEntries(array, key) {
    const countMap = array.reduce((map, item) => {
      const value = item[key];
      map.set(value, (map.get(value) || 0) + 1);
      return map;
    }, new Map());

    return array.filter((item) => countMap.get(item[key]) === 1);
  }

  React.useEffect(() => {
    getUsers(setUsers, setDataFetched);
  }, []);

  return (
    <Autocomplete
      style={style? style : { width: "100%" }}
      limitTags={3}
      multiple
      id="checkboxes-tags-demo"
      options={[
        { id: "all", Vorname: "Alle", Nachname: "auswählen", Avatarfile: "" },
        ...users,
      ]}
      value={data.usersSelected}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        if (newValue && newValue.find((option) => option.id === "all")) {
          updateState("usersSelected", users, setData);
        } else {
          updateState(
            "usersSelected",
            newValue ? removeMultipleEntries(newValue, "id") : "",
            setData
          );
        }
      }}
      getOptionLabel={(option) => option.Vorname + " " + option.Nachname}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={isOptionSelected(option)}
          />
          <UserAvatar
            name={option.Vorname + " " + option.Nachname}
            src={option.Avatarfile}
            marginLeft={"10px"}
            marginRight={"10px"}
          />
          {option.Vorname + " " + option.Nachname}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Empfänger"
          variant="standard"
          onFocus={() => setVisibility(true)}
          onBlur={() => setVisibility(false)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {data.usersSelected.map((user, index) =>
                  index < 7 || visibility === true ? (
                    <Tooltip
                      key={user.id}
                      title={
                        <span style={{ whiteSpace: "pre-line" }}>
                          {user.Vorname +
                            " " +
                            user.Nachname +
                            "\n" +
                            user.Email}
                        </span>
                      }
                    >
                      <div>
                        <UserAvatar
                          name={user.Vorname + " " + user.Nachname}
                          src={user.Avatarfile}
                          marginLeft={"10px"}
                          marginRight={"10px"}
                        />
                      </div>
                    </Tooltip>
                  ) : index === data.usersSelected.length - 1 ? (
                    <div key="more">+ {data.usersSelected.length - 7}</div>
                  ) : null
                )}
              </>
            ),
          }}
          InputLabelProps={{
            shrink: data.usersSelected.length > 0, // Conditionally shrink the label based on selected users
          }}
        />
      )}
      sx={{ width: "500px" }}
    />
  );
}
