import { sendPostRequest } from "./sendPostRequest";
import { updateState } from "../Portal/Questions/questionsFunctions";

export function translateText(text, setText, variable) {
  if (text !== "") {
    updateState(
        variable,
        "KI-Übersetzer arbeitet...",
        setText
      );
    sendPostRequest("postAITranslation.php", {
      text: text,
    }).then((response) => {
      updateState(
        variable,
        JSON.parse(response).choices[0].message.content,
        setText
      );
    });
  }
}

export function translateTextForSurveyPage(text, headers, page, setSurveyData) {
  if (text !== "") {
    const newHeaders = [...headers];
    newHeaders[page - 1].en = "KI-Übersetzer arbeitet...";
    updateState("headers", newHeaders, setSurveyData);
    sendPostRequest("postAITranslation.php", {
      text: text,
    }).then((response) => {
      const newHeaders = [...headers];
      newHeaders[page - 1].en = JSON.parse(response).choices[0].message.content;
      updateState("headers", newHeaders, setSurveyData);
    });
  }
}
