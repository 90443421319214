import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

const PenOption = ({ cellValues }) => {
const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/boards/edit/${cellValues.row.id}`);
  };

  return (
    <MenuItem onClick={handleClick} disableRipple>
    <EditIcon/> Board bearbeiten
    </MenuItem>
  );
};

export default PenOption;