import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

export default function Spinner() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Fade in={show}>
        <CircularProgress size={70} />
      </Fade>
    </div>
  );
}
