import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { showToaster } from "../../Common/showToaster";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MUITextField from "../../Common/MUITextField";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { sendGetRequest } from "../../Common/sendGetRequest";
import { updateState } from "../Surveys/surveysFunctions";


export default function EditCategories(props) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [optionData, setOptionData] = useState({
    id: props.id,
    category: props.category,
  });

  const [errorCategory, setErrorCategory] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSubmit = () => {
    if (optionData.category.length > 0) {
      sendPostRequest("updateCategories.php",optionData).then(
        (response) => {
          handleClose();
          showToaster(
            response,
            "Ein unerwarteter Fehler ist aufgetreten",
            "Änderungen wurden erfolgreich gespeichert"
          );
          sendGetRequest("getCategories.php").then((response) => {
            props.setOptionList(response);
          });
        }
      );
    } else {
        if(optionData.category.length == 0)
        {
            setErrorCategory(true);
        }
    }
  };

  const handleDelete = () => {
      sendPostRequest("deleteCategories.php",optionData).then(
        (response) => {
          handleCloseDelete();
          showToaster(
            response,
            "Ein unerwarteter Fehler ist aufgetreten",
            "Änderungen wurden erfolgreich gespeichert"
          );
          sendGetRequest("getCategories.php").then((response) => {
            props.setOptionList(response);
          });
        }
      );
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} edge="start" aria-label="edit">
          <EditIcon />
      </IconButton>
      <IconButton onClick={handleClickOpenDelete} edge="end" aria-label="delete">
          <DeleteIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Fragenkategorie bearbeiten</DialogTitle>
        <DialogContent>
          <MUITextField
            style={{
              margin: "5px",
              minWidth: "300px",
              width: "90%",
            }}
            handleChange={(event) => {
              updateState("category",event.target.value, setOptionData);
              setErrorCategory(false);
            }}
            inputPropsStyle={{ minWidth: "200px", width: "90%" }}
            id="category"
            label="Kategorie"
            variant="standard"
            value={optionData.category}
            error={errorCategory}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit}>Speichern</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Fragenkategorie löschen</DialogTitle>
        <DialogContent>
            Möchten sie die Kategorie {optionData.category} unwiderruflich löschen? Fragen die dieser Kategorie zugeordnet sind bleiben erhalten.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Abbrechen</Button>
          <Button onClick={handleDelete}>Löschen</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
