import { TextField } from "@mui/material";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

export default function MUIPasswordField(props) {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show); 

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      className={props.className}
      id={props.id}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        style: props.inputPropsStyle,
      }}
      style={props.style}
      label={props.label}
      variant={props.variant}
      value={props.value}
      onChange={props.handleChange}
      error={props.error}
      helperText={props.error ? props.helperText : ""}
    />
  );
}

MUIPasswordField.defaultProps = {
  helperText : 'Pflichtfeld bitte befüllen'
};
