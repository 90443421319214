import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { showToaster } from "../../Common/showToaster";
import EditIcon from "@mui/icons-material/Edit";
import MUITextField from "../../Common/MUITextField";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { sendGetRequest } from "../../Common/sendGetRequest";
import { updateState } from "../Surveys/surveysFunctions";

export default function CreateCategories(props) {
  const [open, setOpen] = useState(false);
  const [optionData, setOptionData] = useState({
    category: "",
  });

  const [errorCategory, setErrorCategory] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (optionData.category.length > 0) {
      sendPostRequest("createCategories.php", optionData).then((response) => {
        handleClose();
        showToaster(
          response,
          "Ein unerwarteter Fehler ist aufgetreten",
          "Änderungen wurden erfolgreich gespeichert"
        );
        sendGetRequest("getCategories.php").then((response) => {
          props.setOptionList(response);
        });
      });
    } else {
      if (optionData.category.length == 0) {
        setErrorCategory(true);
      }
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        Kategorie hinzufügen
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kategorie hinzufügen</DialogTitle>
        <DialogContent>
          <MUITextField
            style={{
              margin: "5px",
              minWidth: "300px",
              width: "90%",
            }}
            handleChange={(event) => {
              updateState("category", event.target.value, setOptionData);
              setErrorCategory(false);
            }}
            inputPropsStyle={{ minWidth: "200px", width: "90%" }}
            id="category"
            label="Kategorie"
            variant="standard"
            value={optionData.category}
            error={errorCategory}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSubmit}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
