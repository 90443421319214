import React from 'react';
import {TextField} from "@mui/material";
export default function MUITextField(props) {

  return (
      <TextField
        style={props.style}
        placeholder={props.placeholder}
        type={props.type}
        className={props.className}
        id={props.id}
        InputProps={props.InputProps}
        onFocus={props.onFocus}
        label={props.label}
        variant={props.variant}
        value={props.value}
        onChange={props.handleChange}
        onClick={props.onClick}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.error ? props.helperText : ''}
        multiline = {props.isMultilineText}
        rows={props.rows}
        maxRows={props.maxRows}
      />
  );
}

MUITextField.defaultProps = {
  helperText : 'Pflichtfeld bitte befüllen, keine Sonderzeichen erlaubt'
};
