import { sendPostRequest } from "../../Common/sendPostRequest";


export const containsSpecialCharacters = (input) => {
  const specialCharactersRegex = /[!@#$%^&*(),.?"':{}|<>]/;
  return specialCharactersRegex.test(input);
};

export function createBoard(
  newBoardData,
  navigate
) {
  sendPostRequest("createBoard.php", newBoardData).then((response) => {
    let responseObject = JSON.parse(response);
    
    if (
      responseObject.message.includes("Board wurde erfolgreich angelegt")
    ) {
      localStorage.setItem(
        "toastMessage",
        "Board wurde erfolgreich angelegt"
      );
        navigate("/boards");
    }
  });
}

export function editBoard(
  newBoardData,
  navigate
) {
  sendPostRequest("editBoard.php", newBoardData).then((response) => {
    let responseObject = JSON.parse(response);
    
    if (
      responseObject.message.includes("Board wurde erfolgreich bearbeitet")
    ) {
      localStorage.setItem(
        "toastMessage",
        "Board wurde erfolgreich bearbeitet"
      );
        navigate("/boards");
    }
    else
    {
      console.log("Fehler beim Bearbeiten des Boards")
    }
  });
}


export function isValidPassword(password) {
  if (password.length < 6) {
    return false;
  }
  const passwordRegex = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
  return passwordRegex.test(password);
}

export async function validateInputFields(newUserData, setErrorNewUserData) {
  const errors = {};
  var allChanged = true;

  // Check if each variable has changed from initial state
  const promises = Object.entries(newUserData).map(([key, value]) => {
    if (key === "password" && !isValidPassword(value) && newUserData.isPasswordProtected) {
      allChanged = false;
      errors[`errorPassword`] = true;
    }
    if (
      key === "boardname" &&
      (value.length < 1)
    ) {
      allChanged = false;
      errors[`errorBoardname`] = true;
    }
    return Promise.resolve();
  });

  await Promise.all(promises);
  if (!allChanged) {
    setErrorNewUserData((prevState) => ({
      ...prevState,
      ...errors,
    }));
  }
  return allChanged;
}

export function deleteValidationErrors(newUserData) {
  const errors = {};

  Object.entries(newUserData).forEach(([key, value]) => {
    errors[`error${key.charAt(0).toUpperCase()}${key.slice(1)}`] = false;
  });
}
